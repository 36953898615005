import {
  GET_COUNTRY_PROFILE,
  GET_COUNTRY_PROFILE_FAIL,
  GET_COUNTRY_PROFILE_SUCCESS,
  GET_COUNTRIES,
  GET_COUNTRIES_FAIL,
  GET_COUNTRIES_SUCCESS,
  ADD_NEW_COUNTRY,
  ADD_COUNTRY_SUCCESS,
  ADD_COUNTRY_FAIL,
  UPDATE_COUNTRY,
  UPDATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_FAIL,
  DELETE_COUNTRY,
  DELETE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_FAIL,
} from "./actionTypes"

export const getCountries = (query) => ({
  type: GET_COUNTRIES,
  query: query
})

export const getCountriesSuccess = countries => ({
  type: GET_COUNTRIES_SUCCESS,
  payload: countries,
})

export const addNewCountry = country => ({
  type: ADD_NEW_COUNTRY,
  payload: country,
})

export const addCountrySuccess = country => ({
  type: ADD_COUNTRY_SUCCESS,
  payload: country,
})

export const addCountryFail = error => ({
  type: ADD_COUNTRY_FAIL,
  payload: error,
})

export const getCountriesFail = error => ({
  type: GET_COUNTRIES_FAIL,
  payload: error,
})

export const getCountryProfile = () => ({
  type: GET_COUNTRY_PROFILE,
})

export const getCountryProfileSuccess = countryProfile => ({
  type: GET_COUNTRY_PROFILE_SUCCESS,
  payload: countryProfile,
})

export const getCountryProfileFail = error => ({
  type: GET_COUNTRY_PROFILE_FAIL,
  payload: error,
})

export const updateCountry = country => ({
  type: UPDATE_COUNTRY,
  payload: country,
})

export const updateCountrySuccess = country => ({
  type: UPDATE_COUNTRY_SUCCESS,
  payload: country,
})

export const updateCountryFail = error => ({
  type: UPDATE_COUNTRY_FAIL,
  payload: error,
})

export const deleteCountry = country => ({
  type: DELETE_COUNTRY,
  payload: country,
})

export const deleteCountrySuccess = country => ({
  type: DELETE_COUNTRY_SUCCESS,
  payload: country,
})

export const deleteCountryFail = error => ({
  type: DELETE_COUNTRY_FAIL,
  payload: error,
})
