import {
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  ADD_COUNTRY_SUCCESS,
  ADD_COUNTRY_FAIL,
  UPDATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_FAIL,
  DELETE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_FAIL,
  GET_COUNTRY_PROFILE_SUCCESS,
  GET_COUNTRY_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  countries: [],
  totalSize: 0,
  from: 0,
  countryProfile: {},
  error: {},
}

const countries = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload.totalData,
        totalSize: action.payload.totalCount[0].count,
        from: action.payload.from,
      }

    case GET_COUNTRIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_COUNTRY_SUCCESS:
      return {
        ...state,
        countries: [...state.countries, action.payload],
        totalSize: state.totalSize+1
      }

    case ADD_COUNTRY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COUNTRY_PROFILE_SUCCESS:
   
      return {
        ...state,
        countryProfile: action.payload,
      }

      case UPDATE_COUNTRY_SUCCESS:
        return {
          ...state,
          countries: state.countries.map(country =>
            country._id.toString() === action.payload._id.toString()
              ? { country, ...action.payload }
              : country
          ),
        }
  
      case UPDATE_COUNTRY_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
      case DELETE_COUNTRY_SUCCESS:
        
        return {
          ...state,
          countries: state.countries.filter(
            country => country._id.toString() !== action.payload._id.toString()
          ),
          totalSize: state.totalSize-1
        }
  
      case DELETE_COUNTRY_FAIL:
        return {
          ...state,
          error: action.payload,
        }

    case GET_COUNTRY_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default countries
