import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Modal, Button, ModalHeader, ModalBody, Alert } from "reactstrap"
import
paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { AvForm, AvField } from "availity-reactstrap-validation"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

import showNotification from '../../components/Common/Notifications'
import Breadcrumbs from "components/Common/Breadcrumb"

import {
    getCountries,
    addNewCountry,
    updateCountry,
    deleteCountry
} from "store/countries/actions"
import _, { isEmpty, size, map } from "lodash"
import DeleteDialog from '../../components/Common/DeleteDialog'
import { countryService } from "services/country.service";
import { SingleSelect } from "components/Common/SingleSelect";

const CountriesList = props => {
    const { countries, onGetCountries } = props
    const [modal, setModal] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
    const [scopedItem, setScopedItem] = useState({})
    const [actionsLoading, setActionsLoading] = useState(false)


    // const onPageChange = (page, sizePerPage) => {
    //     props.history.push(window.location.pathname+'?page='+page)
    // }

    const pageOptions = {
        sizePerPage: 20,
        totalSize: props.totalSize, // replace later with size(countries),
        // onPageChange: onPageChange,
        custom: true,
        page: Math.ceil(props.from/20)
    }
 

    const defaultSorted = [{
        dataField: '_id', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc' // desc or asc
    }];

  

    const countryListColumns = [
        {
            text: "id",
            dataField: "_id",
            sort: true,
            hidden: true,
            formatter: (cellContent, country) => (
                <>
                    {country.id}
                </>
            ),
        },
        {
            text: "Name",
            dataField: "name",
            sort: true
        },
        {
            text: "Code",
            dataField: "code",
            sort: true,
        },


        // {
        //     dataField: "status",
        //     text: "Status",
        //     formatter: (cellContent, country) => (
        //         <div className="d-flex gap-3">
        //             {
        //                 cellContent === 'ACTIVE' ? <span className='badge badge-soft-success rounded-pill float-end ms-1 font-size-12'>ACTIVE</span> 
        //                     : cellContent === 'INACTIVE' ? <span className='badge badge-soft-dark rounded-pill float-end ms-1 font-size-12'>INACTIVE</span> 
        //                     : <span className='badge badge-soft-warning rounded-pill float-end ms-1 font-size-12'>BANNED</span> 
        //             }
        //         </div>
        //     ),
        // },
        {
            dataField: "menu",
            isDummyField: true,
            editable: false,
            text: "Action",
            formatter: (cellContent, country) => (
                <div className="d-flex gap-3">
                    <Link className="text-success" to="#"><i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => handleCountryClick(country)}></i></Link>
                    <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => openDeleteDialog(country)}></i></Link>
                </div>
            ),
        },
    ]

    useEffect(() => {
        if (countries && !countries.length) {
            onGetCountries(window.location.search);
            setIsEdit(false)
        }
    }, [onGetCountries, countries.from, window.location.search]);

    // useEffect(() => {

    //     onGetCountries(window.location.search);
    //     setIsEdit(false)
    
    // }, [window.location.search]);

    useEffect(() => {
        setIsEdit(false)
    }, [countries])

    useEffect(() => {
        if (!isEmpty(countries) && !!isEdit) {
            setIsEdit(false)
        }
    }, [countries])

    const toggle = () => {
        setModal(!modal)
    }

    const [ countryToEdit, setCountryToEdit ] = useState(false)

    const handleCountryClick = arg => {
        setShowError(false)
        const country = arg
        setCountryToEdit({
            _id: country._id,
            code: country.code,
            name: country.name,
        })

        setIsEdit(true)

        setModal(true)
    }

    const openDeleteDialog = (country) => {
        toggleDeleteModal()
        setScopedItem({
            _id: country._id,
            name: country.name
        })
    }

    const handleDeleteCountry = () => {
        setActionsLoading(true)
        const { onDeleteCountry } = props
        onDeleteCountry(scopedItem)
    }


    const handleValidCountrySubmit = (e, values) => {
        console.log(values);
        setActionsLoading(true)
        setShowError(true)
        const { onAddNewCountry, onUpdateCountry } = props
        if (isEdit) {
            const updateCountry = {
                _id: countryToEdit._id,
                code: values.code,
                name: values.name,
            }
            onUpdateCountry(updateCountry)
        } else {
            const newCountry = {
                code: values["code"],
                name: values["name"],
            }
            onAddNewCountry(newCountry)
        }
    }

    useEffect(() => {
        if(modal){
            if(_.isEmpty(props.error)){
                setModal(false)
                setIsEdit(false)
                showNotification({title:'Saved!', message:'Item is saved', type:'success'})
            }
        }
        if(deleteModalIsOpen){
            if(_.isEmpty(props.error)){
                toggleDeleteModal()
                showNotification({title:'Deleted!', message:'Item is gone', type:'success'})
            }
        }
        setActionsLoading(false)
    }, [countries, props.error])

    const handleCountryClicks = () => {
        setShowError(false)
        setCountryToEdit(false)
        setIsEdit(false)
        toggle()
    }


    function toggleDeleteModal() {
        setDeleteModalIsOpen(!deleteModalIsOpen)
        removeBodyCss()
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    return (
        <React.Fragment>
            <DeleteDialog 
                deleteModalIsOpen={deleteModalIsOpen}
                setDeleteModalIsOpen={setDeleteModalIsOpen}
                toggleDeleteModal={toggleDeleteModal}
                scopedItem={scopedItem}
                handleDelete={handleDeleteCountry}
                actionsLoading={actionsLoading}
            />
            <div className="page-content">
                <MetaTags>
                    <title>Country List | Skote - React Admin & Dashboard Template</title>
                </MetaTags>
           
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Contacts" breadcrumbItem="Country List" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                      pagination={paginationFactory(pageOptions)}
                                      keyField='_id'
                                      columns={+countryListColumns}
                                      data={countries}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                              keyField="_id"
                                              data={countries}
                                              columns={countryListColumns}
                                              bootstrap4
                                              search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col sm="12">
                                                                <div className="text-sm-end">
                                                                    <Button
                                                                        color="primary"
                                                                        className="font-16 btn-block btn btn-primary"
                                                                        onClick={handleCountryClicks}
                                                                    >
                                                                        <i className="mdi mdi-plus-circle-outline me-1" />
                                                                        Create New Country
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap table-hover"
                                                                        }
                                                                        remote
                                                                        onTableChange={
                                                                            (e)=>''
                                                                        }
                                                                        bordered={false}
                                                                        striped={false}
                                                                        responsive
                                                                    />

                                                                        <Modal
                                                                            isOpen={modal} toggle={toggle}
                                                                        >
                                                                            <ModalHeader toggle={toggle} tag="h4">
                                                                                {!!isEdit ? "Edit Country" : "Add Country"}
                                                                            </ModalHeader>
                                                                            <ModalBody>
                                                                                <AvForm
                                                                                    onValidSubmit={
                                                                                        handleValidCountrySubmit
                                                                                    }
                                                                                    autoComplete="off"
                                                                                >
                                                                                <Row form>
                                                                                    <Col xs={12}>
                                                                                        {(!_.isEmpty(props.error) && showError) ? (
                                                                                            <Alert color="danger">{props.error.response.data.message ? props.error.response.data.message : props.error.response.data.errors[0].message}</Alert>
                                                                                        ) : null}
                                                                                        <div className="mb-3">
                                                                                            <AvField
                                                                                                name="name"
                                                                                                label="Name"
                                                                                                type="text"
                                                                                                autoComplete="off"
                                                                                                errorMessage="Invalid name"
                                                                                                validate={{
                                                                                                  required: { value: true },
                                                                                                }}
                                                                                                value={countryToEdit.name || ""}
                                                                                            />
                                                                                        </div>
                                                                                        
                                                                                        <div className="mb-3">
                                                                                            <AvField
                                                                                                name="code"
                                                                                                label="Code"
                                                                                                type="text"
                                                                                                autoComplete="off"
                                                                                                errorMessage="Invalid Code"
                                                                                                validate={{
                                                                                                  required: { value: true },
                                                                                                }}
                                                                                                value={countryToEdit.code || ""}
                                                                                            />
                                                                                        </div>
                                                                                        
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col>
                                                                                        <div className="text-end">
                                                                                            <button
                                                                                                type="submit"
                                                                                                className="btn btn-success save-country"
                                                                                                disabled={actionsLoading}
                                                                                            >
                                                                                                {
                                                                                                    actionsLoading 
                                                                                                        ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                                                        : 'Save' 
                                                                                                }
                                                                                            </button>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </AvForm>
                                                                        </ModalBody>
                                                                    </Modal>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {/* <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row> */}
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

CountriesList.propTypes = {
    countries: PropTypes.array,
    onGetCountries: PropTypes.func,
    onAddNewCountry: PropTypes.func,
    onDeleteCountry: PropTypes.func,
    onUpdateCountry: PropTypes.func
}

const mapStateToProps = ({ countries }) => ({
    countries: countries.countries,
    totalSize: countries.totalSize,
    from: countries.from,
    error: countries.error
})

const mapDispatchToProps = dispatch => ({
    onGetCountries: (query) => dispatch(getCountries(query)),
    onAddNewCountry: country => dispatch(addNewCountry(country)),
    onUpdateCountry: country => dispatch(updateCountry(country)),
    onDeleteCountry: country => dispatch(deleteCountry(country)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CountriesList))
