import React from 'react'
import { SingleSelect } from 'components/Common/SingleSelect';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { currencyService } from 'services';
import { countryService } from 'services/country.service';



function ProductForm(props) {

    const [initialValues, setInitialValues] = React.useState({
		name: '',
		symbol: '',
        iso_code: '',
        countries: []
    });

    React.useEffect(() => {
        setInitialValues(props.productToEdit)
    }, [props.productToEdit])

    

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('name is required'),
        symbol: Yup.string()
            .required('symbol is required'),
        iso_code: Yup.string()
            .required('iso_code is required'),
        countries: Yup.array()
            .required('Countries are required'),
    });

    function onSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        props.handleValidProductSubmit(null, fields)
    }

    return (

        <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize={true} onSubmit={onSubmit}>
        {({ errors, touched, isSubmitting, setFieldTouched, handleChange, setFieldValue, values }) => (
            <Form className="signup-form">
            
                
                <div className='col-12 mt-3'>
                    <div className="form-group">
                        <label className="form-label ml-2" for="min"> Name</label>
                        <Field type="text" name="name" placeholder='Name' className='form-control' />
                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                    </div>
                </div>
                <div className='col-12 mt-3'>
                    <div className="form-group">
                        <label className="form-label ml-2" for="min"> Symbol</label>
                        <Field type="text" name="symbol" placeholder='Symbol' className='form-control' />
                        <ErrorMessage name="symbol" component="div" className="invalid-feedback" />
                    </div>
                </div>
                <div className='col-12 mt-3'>
                    <div className="form-group">
                        <label className="form-label ml-2" for="min"> ISO Code</label>
                        <Field type="text" name="iso_code" placeholder='Iso code' className='form-control' />
                        <ErrorMessage name="iso_code" component="div" className="invalid-feedback" />
                    </div>
                </div>
                <div className='col-12 mt-3'>
                    <div className="form-group">
						<SingleSelect
                            isMulti={true}
							value={values.countries}
							onChange={setFieldValue}
							onBlur={setFieldTouched}
							error={errors.countries}
							endPoint={countryService.find}
							touched={touched.countries}
							name={"countries"}
							title={"Countries"}
							extraFilter={false}
                            extraQuery={false}
						/>
                    </div>
                </div>

                <div className='d-flex justify-content-end pt-4'>
                    <button type="submit" disabled={props.actionsLoading} className="btn btn-primary">
                        {props.actionsLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        {
                            props.isEdit ? 'UPDATE CURRENCY' : 'ADD CURRENCY'
                        }
                    </button>
                </div>
            </Form>
       
            )}
            </Formik>
    )


}


export default ProductForm