import { Card, CardBody, Col, Container, Row, Modal, Button, ModalHeader, ModalBody, Alert } from "reactstrap"
import CurrencyForm from "./currency-form"


const ModalCurrencyForm = (props) => {

    return (
        
        <Modal
            isOpen={props.isOpen} 
            toggle={props.toggle}
        >
                <ModalHeader toggle={props.toggle} tag="h4">
                    {!!props.isEdit ? "Edit Currency" : "Add Currency"}
                </ModalHeader>
                <ModalBody>
                {(!_.isEmpty(props.error) && props.showError) ? (
                    <Alert color="danger">{props.error.response.data.message}</Alert>
                ) : null}
                <CurrencyForm 
                    handleValidProductSubmit={props.handleValidProductSubmit}
                    isEdit={props.isEdit}
                    productToEdit={props.productToEdit}
                    actionsLoading={props.actionsLoading}
                    setActionsLoading={props.setActionsLoading}
                />
            </ModalBody>
        </Modal>
    )
}

export default ModalCurrencyForm