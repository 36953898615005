import { del, get, post, put } from "../helpers/api_helper"

const list = (query = '') => get('countries'+query)

const find = (id) => get('countries/'+id)

const create = (params) => post('countries', params)

const update = ({_id, params}) => put(`countries/${_id}`, params)

const _delete = (id) => del(`countries/${id}`)

export const countryService = {
    list,
    find,
    create,
    update,
	delete: _delete,
};