import { call, put, takeEvery } from "redux-saga/effects"

import { GET_COUNTRIES, GET_COUNTRY_PROFILE , ADD_NEW_COUNTRY , DELETE_COUNTRY, UPDATE_COUNTRY } from "./actionTypes"

import {
    getCountriesSuccess,
    getCountriesFail,
    getCountryProfileSuccess,
    getCountryProfileFail,
    addCountryFail,
    addCountrySuccess,
    updateCountrySuccess,
    updateCountryFail,
    deleteCountrySuccess,
    deleteCountryFail,
} from "./actions"
import { countryService } from "services/country.service"



function* fetchCountries({payload: query}) {
    try {
        const response = yield call(countryService.list, query)
        yield put(getCountriesSuccess(response))
    } catch (error) {
        yield put(getCountriesFail(error))
    }
}

function* fetchCountryProfile() {
    try {
        const response = yield call(countryService.find)
        yield put(getCountryProfileSuccess(response))
    } catch (error) {
        yield put(getCountryProfileFail(error))
    }
}

function* onUpdateCountry({ payload: country }) {
    try {
        yield call(countryService.update, {
            _id:country._id,
            params:{
                ...country, 
            }
        })
        yield put(updateCountrySuccess(country))
    } catch (error) {
        yield put(updateCountryFail(error))
    }
}

function* onDeleteCountry({ payload: country }) {
    try {
        const response = yield call(countryService.delete, country._id)
        yield put(deleteCountrySuccess(response))
    } catch (error) {
        yield put(deleteCountryFail(error))
    }
}

function* onAddNewCountry({ payload: country }) {
    try {
        const response = yield call(countryService.create, {
          ...country, 
        })
        yield put(addCountrySuccess(Object.assign({}, country, response)))
        yield put(addCountryFail(null))
    } catch (error) {
        console.log(error);
        console.log('sdasd');
        yield put(addCountryFail(error))
    }
}

function* countriesSaga() {
    yield takeEvery(GET_COUNTRIES, fetchCountries)
    yield takeEvery(GET_COUNTRY_PROFILE, fetchCountryProfile)
    yield takeEvery(ADD_NEW_COUNTRY, onAddNewCountry)
    yield takeEvery(UPDATE_COUNTRY, onUpdateCountry)
    yield takeEvery(DELETE_COUNTRY, onDeleteCountry)
}

export default countriesSaga;
