import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"


import currencies from "./currencies/reducer"
import products from "./products/reducer"
import countries from "./countries/reducer"

//contacts

//accounts
// import accounts from "./accounts/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  currencies,
  products,
  countries
  // accounts,
})

export default rootReducer
