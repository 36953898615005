/* COUNTRIES */
export const GET_COUNTRIES = "GET_COUNTRIES"
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS"
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL"

/* COUNTRIES PROFILE */
export const GET_COUNTRY_PROFILE = "GET_COUNTRY_PROFILE"
export const GET_COUNTRY_PROFILE_SUCCESS = "GET_COUNTRY_PROFILE_SUCCESS"
export const GET_COUNTRY_PROFILE_FAIL = "GET_COUNTRY_PROFILE_FAIL"

/**
 * add account
 */
export const ADD_NEW_COUNTRY = "ADD_NEW_COUNTRY"
export const ADD_COUNTRY_SUCCESS = "ADD_COUNTRY_SUCCESS"
export const ADD_COUNTRY_FAIL = "ADD_COUNTRY_FAIL"

/**
 * Edit account
 */
export const UPDATE_COUNTRY = "UPDATE_COUNTRY"
export const UPDATE_COUNTRY_SUCCESS = "UPDATE_COUNTRY_SUCCESS"
export const UPDATE_COUNTRY_FAIL = "UPDATE_COUNTRY_FAIL"

/**
 * Delete account
 */
export const DELETE_COUNTRY = "DELETE_COUNTRY"
export const DELETE_COUNTRY_SUCCESS = "DELETE_COUNTRY_SUCCESS"
export const DELETE_COUNTRY_FAIL = "DELETE_COUNTRY_FAIL"
